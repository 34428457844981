/* You can add global styles to this file, and also import other style files */
.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover 
{
  background-color: #9D54F7 !important; 
}
.currency-title{
  height: 50px;
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: bold;
}
.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.selectr-container .selectr-selected,
.selectr-container.has-selected .selectr-selection-multiple,
.selectr-options-container .selectr-selection-multiple,
.selectr-options-container .selectr-input-container .selectr-input {
  font-size: 0.75rem!important;
  line-height: 1.5!important;
  padding: 0.25rem 0.5rem!important;
  height: auto!important;
}

// datatable styles
// =======================================
.datatable-pager .pager > li:not(.pages) > a {
  background-color: #9D54F7 !important;
  color: #fff !important;
}

// ESTILO PARA LA PAGINACIÓN QUE NO ES ACTIVA
.datatable-pager .pages > a, .data-range-picker {
/*   background: white !important;
 */  background-color: #FFA23A20 !important;
  color: #9D54F7 !important;
}


// ESTILO PARA LA PAGINACIÓN ACTIVA
.datatable-pager .active > a {
  background-color: #9D54F7 !important;
  color: white !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: rgba(157, 84, 247, 0.825) !important;
  color: #fff !important; 
}

.page-content-custom {
  display: contents !important;
}

.datatable-header-multiline {
  white-space: break-spaces !important;
}
/* @media (max-width: @screen-xs) {
    .form-group label{font-size: 10px;}
}

@media (max-width: @screen-sm) {
    .form-group label{font-size: 14px;}
}

.form-group label{
    font-size: 1.4em;
} */
.invalid-field{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
/* .input-group{
  border: 1px;
} */
.input-group.is-invalid{
  border: 1px solid #fb6340;
}
.form-group .is-invalid{
  border: 1px solid #fb6340;
}
.input-group.is-invalid .form-control{
  border: 0;
}
.input-group.is-invalid .input-group-text{
  border: 0;
}

.file-input{
  display: none;
}